import router, { createRouter } from './router'
import store from './store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken, processPermission } from '@/utils/auth' // get token from cookie
import pageTitle from '@/utils/page-title'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = [
  '/services/data-center/weekly-board',
  '/about',
  '/signin',
  '/signup',
  '/verify',
  '/404',
] // no redirect whitelist

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start()

  // set page title
  document.title = pageTitle(to.meta.title)

  // determine whether the user has logged in
  const hasToken = getToken()

  if (hasToken) {
    if (to.path === '/signin' || to.path === '/signup') {
      // if is logged in, redirect to the home page
      next({ path: '/' })
      NProgress.done()
    } else {
      // determine whether the user has obtained his permission roles through getInfo
      const hasUserInfo = store.getters.my && store.getters.my.id > 0
      if (hasUserInfo) {
        next()
      } else {
        try {
          // get user info
          // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
          const { adminType } = await store.dispatch('user/fetchMy')
          let roles = ['user']
          if (adminType.length > 0) {
            roles.push('admin')
          }

          // generate accessible routes map based on roles
          const accessRoutes = await store.dispatch('permission/generateRoutes', roles)

          // dynamically add accessible routes
          // hack method to avoid duplicate routes warning in console
          // https://github.com/vuejs/vue-router/issues/1727#issuecomment-476593138
          router.matcher = createRouter(accessRoutes).matcher;
          // router.addRoutes(router.options.routes)

          // hack method to ensure that addRoutes is complete
          // set the replace: true, so the navigation will not leave a history record
          next({ ...to, replace: true })
        } catch (error) {
          // remove token and go to login page to re-login
          await store.dispatch('user/resetToken')
          console.log(error) // for debug
          next(`/signin?redirect=${to.path}`)
          NProgress.done()
        }
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      await store.dispatch('user/fetchGuest')
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/signin?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(to => {
  // finish progress bar
  NProgress.done()
  processPermission(to)
})
