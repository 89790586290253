import { CategoryList, AdditionalT2 } from "@/constants";
import taggerMapping from "@/assets/tagger_mapping.json";
import taggerOptions from "@/assets/tagger_options.json";
import taggerTrendPoint from "@/assets/tagger_trend_point.json";
import taggerTrendPointTagger from "@/assets/tagger_trend_point_22fw.json"
import { fetchCategory } from "@/api/admin";

const state = {
  t2Flatten: [],
  t2AdditionalFlatten: [],
  t1t2Simple: [],
  taggerMapping: taggerMapping,
  taggerOptions: taggerOptions,
  taggerTrendPoint: taggerTrendPoint,
  taggerTrendPointTagger: taggerTrendPointTagger,
}

function sortted(x, y) {
  if (x.value < y.value) {
    return -1;
  } else if (x.value > y.value) {
    return 1;
  }
  return 0;
}

function flatten(arr, displayList) {
  let x = [].concat(...arr.map(x => Array.isArray(x.children) ? x.children : x))
  x.forEach(t => {
    t.children && t.children.sort(sortted);
    t.children && t.children.forEach(e => {
      for (let i = 0; i < displayList.length; i++) {
        if (displayList[i].name === e.value && displayList[i].level === 3 && !displayList[i].additional) {
          e.value = displayList[i].displayName;
          e.label = displayList[i].displayName;
        }
      }
    })
    for (let i = 0; i < displayList.length; i++) {
      if (displayList[i].name === t.value && displayList[i].level === 2 && !displayList[i].additional) {
        t.value = displayList[i].displayName;
        t.label = displayList[i].displayName;
      }
    }
  })
  return x.sort(sortted)
}

const mutations = {
  SET_CATEGORY: (state, displayList) => {
    // step1: t1t2Simple
    let categoryList = JSON.parse(JSON.stringify(CategoryList))
    state.t1t2Simple = categoryList.map(x => {
      x.children.map(y => {
        y.children = null;
        for (let i = 0; i < displayList.length; i++) {
          if (displayList[i].name === y.value && displayList[i].level === 2) {
            y.value = displayList[i].displayName;
            y.label = displayList[i].displayName;
          }
        }
        return y;
      });
      for (let i = 0; i < displayList.length; i++) {
        if (displayList[i].name === x.value && displayList[i].level === 1) {
          x.value = displayList[i].displayName;
          x.label = displayList[i].displayName;
        }
      }
      return x;
    });

    // step2: t2Flatten
    state.t2Flatten = flatten(JSON.parse(JSON.stringify(CategoryList)), displayList);

    let additionals = JSON.parse(JSON.stringify(AdditionalT2));
    additionals.forEach(e => {
      for (let i = 0; i < displayList.length; i++) {
        if (displayList[i].name === e.value && displayList[i].additional) {
          e.value = displayList[i].displayName
          e.label = displayList[i].displayName;
        }
      }
    });
    // step3: additional t2Flatten
    state.t2AdditionalFlatten = state.t2Flatten.concat(...additionals);

    let tm = JSON.parse(JSON.stringify(taggerMapping));
    let tt = JSON.parse(JSON.stringify(taggerTrendPoint));
    let ttt = JSON.parse(JSON.stringify(taggerTrendPointTagger));
    for (let i = 0; i < displayList.length; i++) {
      // step4: tagger mapping
      if (displayList[i].level === 2 && !displayList[i].additional && tm[displayList[i].name]) {
        Object.defineProperty(tm, displayList[i].displayName,
          Object.getOwnPropertyDescriptor(tm, displayList[i].name));
        delete tm[displayList[i].name];
      }

      // step5: tagger trend point
      if (displayList[i].level === 2 && !displayList[i].additional && tt[displayList[i].name]) {
        Object.defineProperty(tt, displayList[i].displayName,
          Object.getOwnPropertyDescriptor(tt, displayList[i].name));
        delete tt[displayList[i].name];
      }

      // step6: tagger trend point for tagger
      if (displayList[i].level === 2 && !displayList[i].additional && ttt[displayList[i].name]) {
        Object.defineProperty(ttt, displayList[i].displayName,
          Object.getOwnPropertyDescriptor(ttt, displayList[i].name));
        delete ttt[displayList[i].name];
      }
    }
    state.taggerMapping = tm
    Object.keys(tt).forEach(function (key) {
      tt[key].push("No detection")
    })
    Object.keys(ttt).forEach(function (key) {
      ttt[key].push("No detection")
    })
    state.taggerTrendPoint = tt
    state.taggerTrendPointTagger = ttt
  }
}

const actions = {
  fresh({ commit }) {
    return new Promise((resolve, reject) => {
      fetchCategory({ limit: -1 }).then(response => {
        const { result } = response
        commit('SET_CATEGORY', result && result.items ? result.items : [])
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
