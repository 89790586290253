import dayjs from 'dayjs';

export function displayTime(str) {
    if (str === '' || str === '0001-01-01T00:00:00Z') {
        return '-'
    } else {
        return dayjs(str).format('YYYY-MM-DD HH:mm:ss')
    }
}

export function elapsedSeconds(str) {
    if (str === '' || str === '0001-01-01T00:00:00Z') {
        return 0
    } else {
        return dayjs().diff(dayjs(str), 'second');
    }
}

export function getPixelRatio(context) {
    var backingStore =
        context.backingStorePixelRatio ||
        context.webkitBackingStorePixelRatio ||
        context.mozBackingStorePixelRatio ||
        context.msBackingStorePixelRatio ||
        context.oBackingStorePixelRatio ||
        context.backingStorePixelRatio ||
        1;
    return (window.devicePixelRatio || 1) / backingStore;
}