export const HomeURL = process.env.VUE_APP_ENV && process.env.VUE_APP_ENV.endsWith(".kr") ? 'https://console.fplusai.com' : 'https://console.fplusai.com.cn';
export const ContactUsURL = process.env.VUE_APP_ENV && process.env.VUE_APP_ENV.endsWith(".kr") ? 'https://www.fplusai.com/#who' : 'https://www.fplusai.com.cn/#who'
export const OssSourceURL = process.env.VUE_APP_ENV && process.env.VUE_APP_ENV.endsWith(".kr") ? 'http://ai-source-files-kr.oss-ap-northeast-2.aliyuncs.com' : 'https://ai-source-files.oss-cn-shanghai.aliyuncs.com/'
export const PAGE_SIZE = 10;
export const ServiceList = [{
  code: 'tagger',
  i18n: 'route.tagger',
}, {
  code: 'analysis',
  i18n: 'route.analysis',
}, {
  code: 'data',
  i18n: 'route.dataCenter',
}, {
  code: 'visual',
  i18n: 'route.visual',
}, {
  code: 'report',
  i18n: 'route.reportConsulting',
}, {
  code: 'nlp',
  i18n: 'route.nlpAnalysis',
}, {
  code: 'gpt',
  i18n: 'route.aiStyleScene',
}, {
  code: 'gptDesigner',
  i18n: 'route.fashionAIDesigner',
}, {
  code: 'gptMorph',
  i18n: 'route.modelAIMorph',
}, {
  code: 'gptConcepter',
  i18n: 'route.fashionAIConcepter',
}, {
  code: 'gptDrawing',
  i18n: 'route.fashionAIDrawing',
}, {
  code: 'globalTrend',
  i18n: 'route.globalTrend',
}, {
  code: 'aeye',
  i18n: 'route.aeye',
}]
import categoryList from "@/assets/category_list.json";
export const CategoryList = categoryList;
export const AdditionalT2 = [
  {
    value: 'Athletic Wear',
    label: 'Athletic Wear'
  },
  {
    value: 'Children Wear',
    label: 'Children Wear'
  },
  {
    value: 'Special Wear',
    label: 'Special Wear'
  },
  {
    value: 'Bag',
    label: 'Bag'
  },
  {
    value: 'Cap',
    label: 'Cap'
  },
  {
    value: 'Fashion Accessory',
    label: 'Fashion Accessory'
  },
  {
    value: 'Fashion Shoes',
    label: 'Fashion Shoes'
  },
  {
    value: 'Jewelry',
    label: 'Jewelry'
  },
  {
    value: 'Baby Shoes',
    label: 'Baby Shoes'
  },
  {
    value: 'Sports Shoes',
    label: 'Sports Shoes'
  },
  {
    value: 'Baby Carriage',
    label: 'Baby Carriage'
  },
  {
    value: 'Baby Goods',
    label: 'Baby Goods'
  },
  {
    value: 'Cleanliness',
    label: 'Cleanliness'
  },
  {
    value: 'Cosmetics',
    label: 'Cosmetics'
  },
  {
    value: 'Diaper',
    label: 'Diaper'
  },
  {
    value: 'Sporting Goods',
    label: 'Sporting Goods'
  },
  {
    value: 'UnderWare Goods',
    label: 'UnderWare Goods'
  },
  {
    value: 'Bra',
    label: 'Bra'
  },
  {
    value: 'Bra.Panties Set',
    label: 'Bra.Panties Set'
  },
  {
    value: 'Panties',
    label: 'Panties'
  },
  {
    value: 'ShapeWear',
    label: 'ShapeWear'
  },
  {
    value: 'Sleep.Home Wear',
    label: 'Sleep.Home Wear'
  },
  {
    value: 'Thermal',
    label: 'Thermal'
  },
  {
    value: 'Children Toys',
    label: 'Children Toys'
  },
  {
    value: 'OTHER',
    label: 'OTHER'
  },
  {
    value: 'Perfume',
    label: 'Perfume'
  },
  {
    value: 'Makeup',
    label: 'Makeup'
  },
  {
    value: 'Cleansing',
    label: 'Cleansing'
  },
  {
    value: 'Skincare',
    label: 'Skincare'
  },
  {
    value: 'Masks',
    label: 'Masks'
  },
  {
    value: 'Beauty Goods',
    label: 'Beauty Goods'
  },
  {
    value: 'Hair Care',
    label: 'Hair Care'
  },
  {
    value: 'Hair Styling',
    label: 'Hair Styling'
  },
  {
    value: 'Man Beauty Care',
    label: 'Man Beauty Care'
  },
  {
    value: 'Nail care',
    label: 'Nail care'
  },
  {
    value: 'Sun Care',
    label: 'Sun Care'
  },
]
