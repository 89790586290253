import { signin, fetchMy, fetchGuest } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'

const state = {
  token: getToken(),
  my: null,
  allowService: true,
  permitMenu: true,
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_MY: (state, my) => {
    state.my = my
  },
  SET_ALLOW_SERVICE: (state, allowService) => {
    state.allowService = allowService
  },
  SET_PERMIT_MENU: (state, permitMenu) => {
    state.permitMenu = permitMenu
  }
}

const actions = {
  signin({ commit }, authInfo) {
    const { email, password } = authInfo
    return new Promise((resolve, reject) => {
      signin({ email: email.trim(), password }).then(response => {
        const { result } = response
        if (result["redirect"]) {
          resolve(result["redirect"])
        } else {
          commit('SET_TOKEN', result.token)
          setToken(result.token)
          resolve()
        }
      }).catch(error => {
        reject(error)
      })
    })
  },

  fetchMy({ commit }) {
    return new Promise((resolve, reject) => {
      fetchMy().then(response => {
        const { result } = response
        commit('SET_MY', result)
        resolve(result)
      }).catch(error => {
        reject(error)
      })
    })
  },

  fetchGuest({ commit }) {
    return new Promise((resolve, reject) => {
      fetchGuest().then(response => {
        const { result } = response
        commit('SET_MY', result)
        resolve(result)
      }).catch(error => {
        reject(error)
      })
    })
  },

  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_MY', null)
      removeToken()
      resolve()
    })
  },

  processPermission({ commit, state }, to) {
    // allow
    let code = to.meta && to.meta.service && !to.meta.allowFree ? to.meta.service : null;
    const my = state.my
    let allow = true
    if (my && code) {
      allow = false
      my.serviceCodes && my.serviceCodes.forEach(e => {
        if (e === code) {
          allow = true
        }
      })
    }
    commit('SET_ALLOW_SERVICE', allow)

    // permission
    let key = to.meta && to.meta.key ? to.meta.key : null;
    let permit = true
    if (my && key) {
      let findKey = (menuList, key) => {
        for (let i = 0; i < menuList.length; i++) {
          if (menuList[i].key === key) {
            return true
          }
          if (findKey(menuList[i].children ? menuList[i].children : [], key)) {
            return true
          }
        }
        return false
      }
      permit = findKey(my.role.menu ? my.role.menu : [], key);
    }
    commit('SET_PERMIT_MENU', permit)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
