<template>
  <div class="app-wrapper">
    <div>
      <navbar />
    </div>
    <app-main />
    <ai-footer />
    <ai-notification />
  </div>
</template>

<script>
import { AppMain, Navbar } from "./components";
import AiFooter from "@/components/AiFooter.vue";
import AiNotification from "@/components/AiNotification.vue";
import { mapState } from "vuex";

export default {
  name: "Layout",
  components: {
    AppMain,
    Navbar,
    AiFooter,
    AiNotification,
  },
  computed: {
    ...mapState({}),
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";

.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;

  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}

.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}
</style>
