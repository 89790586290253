// import displayTime and set to filter
export { displayTime } from '@/utils'

import { ossRoot } from "@/utils/server";
import { ServiceList } from "@/constants";

// 将图片处理成缩略图的形式，外部图片除外（非OSS图片无法处理）
export function imageThumbnailUrl(url, external) {
  if (!url) {
    return ""
  } else if (external) {
    return url
  }
  return ossUrl(url) + '?x-oss-process=image/format,jpg/quality,q_90/resize,m_pad,h_200,w_200,limit_0,color_FAFAFA'
}

export function imageUrl(url, external) {
  if (!url) {
    return ""
  } else if (external) {
    return url
  }
  return ossUrl(url)
}

export function ossUrl(url) {
  // oss url对于encode的实现有问题，错误处理了'+'
  // https://www.codesky.me/archives/oss-design-problem.wind
  return ossRoot + '/' + url.replace('+', '%2B')
}

export function serviceI18n(code) {
  for (let i = 0; i < ServiceList.length; i++) {
    if (ServiceList[i].code === code) {
      return ServiceList[i].i18n
    }
  }
  return ""
}
