import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Layout from '@/layout'
import ServiceLayout from '@/layout/service'

export const asyncRoutes = [
  {
    path: '/',
    component: Layout,
    name: 'Home',
    redirect: { name: 'Weekly Board' },
    meta: {
      title: 'Home',
      roles: ['admin', 'user']
    },
    children: [
      {
        path: 'services',
        component: ServiceLayout,
        redirect: { name: 'Weekly Board' },
        children: [
          {
            path: 'tagger',
            component: () => import('@/views/services/tagger/List.vue'),
            name: 'Tagger',
            meta: {
              key: "tagger",
              service: "tagger"
            }
          },
          {
            path: 'tagger/new',
            component: () => import('@/views/services/tagger/New.vue'),
            name: 'Tagger New',
            meta: {
              key: "tagger",
              service: "tagger"
            }
          },
          {
            path: 'tagger/:id(\\d+)',
            component: () => import('@/views/services/tagger/Detail.vue'),
            name: 'Tagger Detail',
            meta: {
              key: "tagger",
              service: "tagger"
            }
          },
          {
            path: 'data-center/sales',
            component: () => import('@/views/services/data/DetailData.vue'),
            name: 'Analysis Data Sales',
            meta: {
              key: "sales",
              service: "data"
            }
          },
          {
            path: 'data-center/stock',
            component: () => import('@/views/services/data/DetailData.vue'),
            name: 'Analysis Data Stock',
            meta: {
              key: "stock",
              service: "data"
            }
          },
          {
            path: 'data-center/image-data',
            component: () => import('@/views/services/data/ImageData.vue'),
            name: 'Image Data',
            meta: {
              key: "image-data",
              service: "data",
              type: "images"
            }
          },
          {
            path: 'global-trends/item-dashboard',
            component: () => import('@/views/services/global-trends/Dashboard.vue'),
            name: 'Item Dashboard',
            meta: {
              key: "item-dashboard",
              service: "globalTrend",
              type: "specificItem"
            }
          },
          {
            path: 'global-trends/trend-dashboard',
            component: () => import('@/views/services/global-trends/Dashboard.vue'),
            name: 'Trend Dashboard',
            meta: {
              key: "trend-dashboard",
              service: "globalTrend",
              type: "trendPoint"
            }
          },
          {
            path: 'global-trends/item-compare',
            component: () => import('@/views/services/global-trends/Compare.vue'),
            name: 'Item Compare',
            meta: {
              key: "item-compare",
              service: "globalTrend",
              type: "specificItem"
            }
          },
          {
            path: 'global-trends/trend-compare',
            component: () => import('@/views/services/global-trends/Compare.vue'),
            name: 'Trend Compare',
            meta: {
              key: "trend-compare",
              service: "globalTrend",
              type: "trendPoint"
            }
          },
          {
            path: 'global-trends/item-search',
            component: () => import('@/views/services/global-trends/Search.vue'),
            name: 'Item Search',
            meta: {
              key: "item-search",
              service: "globalTrend",
              type: "specificItem"
            }
          },
          {
            path: 'global-trends/trend-search',
            component: () => import('@/views/services/global-trends/Search.vue'),
            name: 'Trend Search',
            meta: {
              key: "trend-search",
              service: "globalTrend",
              type: "trendPoint"
            }
          },
          {
            path: 'global-trends/item-prediction',
            component: () => import('@/views/services/report/UserReport.vue'),
            name: 'Item Prediction',
            meta: {
              key: "item-prediction",
              service: "globalTrend",
              type: "item-prediction"
            }
          },
          {
            path: 'global-trends/trend-prediction',
            component: () => import('@/views/services/report/UserReport.vue'),
            name: 'Trend Prediction',
            meta: {
              key: "trend-prediction",
              service: "globalTrend",
              type: "trend-prediction"
            }
          },
          {
            path: 'visual/street-trend',
            component: () => import('@/views/services/report/UserReport.vue'),
            name: 'Street Trend',
            meta: {
              key: "street-trend",
              service: "visual",
              type: "street"
            }
          },
          {
            path: 'visual/window-trend',
            component: () => import('@/views/services/report/UserReport.vue'),
            name: 'Window Trend',
            meta: {
              key: "window-trend",
              service: "visual",
              type: "window"
            }
          },
          {
            path: 'visual/global-trend',
            component: () => import('@/views/services/visual/GlobalTrend.vue'),
            name: 'Global Trend',
            meta: {
              key: "global-trend",
              service: "visual",
              type: 'global'
            }
          },
          {
            path: 'analysis/sales-item',
            component: () => import('@/views/services/analysis/SalesItem.vue'),
            name: 'Sales Item List',
            meta: {
              key: "sales-item",
              service: "analysis"
            }
          },
          {
            path: 'analysis/sales-trend',
            component: () => import('@/views/services/analysis/SalesTrend.vue'),
            name: 'Sales Trend',
            meta: {
              key: "sales-trend",
              service: "analysis"
            }
          },
          {
            path: 'analysis/weekly-sales',
            component: () => import('@/views/services/analysis/WeeklySales.vue'),
            name: 'Weekly Sales',
            meta: {
              key: "weekly-sales",
              service: "analysis"
            }
          },
          {
            path: 'analysis/style-search',
            component: () => import('@/views/services/analysis/StyleSearch.vue'),
            name: 'Style Search',
            meta: {
              key: "style-search",
              service: "analysis"
            }
          },
          {
            path: 'analysis/new-product',
            component: () => import('@/views/services/analysis/NewProduct.vue'),
            name: 'New Product',
            meta: {
              key: "new-product",
              service: "analysis"
            }
          },
          {
            path: 'analysis/rising-product',
            component: () => import('@/views/services/analysis/RisingProduct.vue'),
            name: 'Rising Product',
            meta: {
              key: "rising-product",
              service: "analysis"
            }
          },
          {
            path: 'analysis/product-detail',
            component: () => import('@/views/services/analysis/ProductDetail.vue'),
            name: 'Product Detail',
            meta: {
              key: "product-detail",
              service: "analysis"
            }
          },
          {
            path: 'analysis/channel',
            component: () => import('@/views/services/analysis/Channel.vue'),
            name: 'Channel',
            meta: {
              key: "channel",
              service: "analysis"
            }
          },
          {
            path: 'analysis/place',
            component: () => import('@/views/services/analysis/Place.vue'),
            name: 'Place',
            meta: {
              key: "place",
              service: "analysis"
            }
          },
          {
            path: 'analysis/concept',
            component: () => import('@/views/services/analysis/Concept.vue'),
            name: 'Concept',
            meta: {
              key: "concept",
              service: "analysis"
            }
          },
          {
            path: 'analysis/specific-item',
            component: () => import('@/views/services/analysis/SpecificItem.vue'),
            name: 'Specific Item',
            meta: {
              key: "specific-item",
              service: "analysis"
            }
          },
          {
            path: 'analysis/best10',
            component: () => import('@/views/services/analysis/List.vue'),
            name: 'Analysis Best10',
            meta: {
              key: "best20-report",
              service: "analysis"
            }
          },
          {
            path: 'analysis/tps',
            component: () => import('@/views/services/analysis/TPS.vue'),
            name: 'Analysis TPS',
            meta: {
              key: "tps",
              service: "analysis"
            }
          },
          {
            path: 'analysis/matrix',
            component: () => import('@/views/services/analysis/List.vue'),
            name: 'Analysis Matrix',
            meta: {
              key: "matrix",
              service: "analysis"
            }
          },
          {
            path: 'analysis/best10/generate',
            component: () => import('@/views/services/analysis/Generate.vue'),
            name: 'Analysis Best10 Report Generate',
            meta: {
              key: "best20-report",
              service: "analysis"
            }
          },
          {
            path: 'analysis/tps/generate',
            component: () => import('@/views/services/analysis/GenerateTPS.vue'),
            name: 'Analysis TPS Report Generate',
            meta: {
              key: "tps",
              service: "analysis"
            }
          },
          {
            path: 'analysis/matrix/generate',
            component: () => import('@/views/services/analysis/Generate.vue'),
            name: 'Analysis Matrix Report Generate',
            meta: {
              key: "matrix",
              service: "analysis"
            }
          },
          {
            path: 'analysis/best20-images',
            component: () => import('@/views/services/analysis/Best20Image.vue'),
            name: 'Analysis Best20 Image',
            meta: {
              key: "best20-images",
              service: "analysis"
            }
          },
          {
            path: 'report/image-trend-map',
            component: () => import('@/views/services/report/ImageTrendMap.vue'),
            name: 'Image Trend Map',
            meta: {
              key: "image-trend-map",
              service: "report"
            }
          },
          {
            path: 'report/price-report',
            component: () => import('@/views/services/report/PriceReport.vue'),
            name: 'Price Report',
            meta: {
              key: "price-report",
              service: "report"
            }
          },
          {
            path: 'report/item-report',
            component: () => import('@/views/services/report/ItemReport.vue'),
            name: 'Item Report',
            meta: {
              key: "item-report",
              service: "report"
            }
          },
          {
            path: 'report/color-report',
            component: () => import('@/views/services/report/ColorReport.vue'),
            name: 'Color Report',
            meta: {
              key: "color-report",
              service: "report"
            }
          },
          {
            path: 'report/textile-report',
            component: () => import('@/views/services/report/TextileReport.vue'),
            name: 'Textile Report',
            meta: {
              key: "textile-report",
              service: "report"
            }
          },
          {
            path: 'report/consulting-report',
            component: () => import('@/views/services/report/UserReport.vue'),
            name: 'Consulting Report',
            meta: {
              key: "consulting-report",
              service: "report",
              type: "monthly"
            }
          },
          {
            path: 'report/global-trend-report',
            component: () => import('@/views/services/report/UserReport.vue'),
            name: 'Global Trend Report',
            meta: {
              key: "global-trend-report",
              service: "report",
              type: "global"
            }
          },
          {
            path: 'nlp/search-word',
            component: () => import('@/views/services/nlp/Analysis.vue'),
            name: 'Search Word',
            meta: {
              key: "search-word",
              service: "nlp",
              source: "SW"
            }
          },
          {
            path: 'nlp/product-name',
            component: () => import('@/views/services/nlp/Analysis.vue'),
            name: 'Product Name',
            meta: {
              key: "product-name",
              service: "nlp",
              source: "PN"
            }
          },
          {
            path: 'nlp/popular-elements',
            component: () => import('@/views/services/nlp/Analysis.vue'),
            name: 'Popular Elements',
            meta: {
              key: "popular-elements",
              service: "nlp",
              source: "PE"
            }
          },
          {
            path: 'nlp/product-reviews',
            component: () => import('@/views/services/report/UserReport.vue'),
            name: 'Product Reviews',
            meta: {
              key: "product-reviews",
              service: "nlp",
              type: "product-reviews"
            }
          },
          {
            path: 'nlp/sns',
            component: () => import('@/views/services/report/UserReport.vue'),
            name: 'SNS',
            meta: {
              key: "sns",
              service: "nlp",
              type: "sns",
            }
          },
          {
            path: 'gpt/creator',
            component: () => import('@/views/services/gpt/Creator.vue'),
            name: 'Creator',
            meta: {
              key: "creator",
              service: "gpt"
            }
          },
          {
            path: 'gpt/creator/new',
            component: () => import('@/views/services/gpt/New.vue'),
            name: 'GPT New',
            meta: {
              key: "creator",
              service: "gpt"
            }
          },
          {
            path: 'gpt/creator/:id(\\d+)',
            component: () => import('@/views/services/gpt/Detail.vue'),
            name: 'GPT Detail',
            meta: {
              key: "creator",
              service: "gpt"
            }
          },
          {
            path: 'gpt/lookbook',
            component: () => import('@/views/services/gpt/Lookbook.vue'),
            name: 'My LOOKBOOK',
            meta: {
              key: "lookbook",
              service: "gpt"
            }
          },
          {
            path: 'gpt-designer/designer',
            component: () => import('@/views/services/gpt-designer/Designer.vue'),
            name: 'AI Design',
            meta: {
              key: "designer",
              service: "gptDesigner"
            }
          },
          {
            path: 'gpt-designer/designer/new',
            component: () => import('@/views/services/gpt-designer/New.vue'),
            name: 'AI Design New',
            meta: {
              key: "designer",
              service: "gptDesigner"
            }
          },
          {
            path: 'gpt-designer/designer/:id(\\d+)',
            component: () => import('@/views/services/gpt-designer/Detail.vue'),
            name: 'AI Design Detail',
            meta: {
              key: "designer",
              service: "gptDesigner"
            }
          },
          {
            path: 'gpt-designer/sketches',
            component: () => import('@/views/services/gpt-designer/Sketches.vue'),
            name: 'Fashion Sketches',
            meta: {
              key: "sketches",
              service: "gptDesigner"
            }
          },
          {
            path: 'gpt-morph/morph',
            component: () => import('@/views/services/gpt-morph/Morph.vue'),
            name: 'Model Replace',
            meta: {
              key: "morph",
              service: "gptMorph",
            }
          },
          {
            path: 'gpt-morph/morph/new',
            component: () => import('@/views/services/gpt-morph/New.vue'),
            name: 'Model Replace New',
            meta: {
              key: "morph",
              service: "gptMorph"
            }
          },
          {
            path: 'gpt-morph/morph/:id(\\d+)',
            component: () => import('@/views/services/gpt-morph/Detail.vue'),
            name: 'Model Replace Detail',
            meta: {
              key: "morph",
              service: "gptMorph"
            }
          },
          {
            path: 'gpt-morph/library',
            component: () => import('@/views/services/gpt-morph/Library.vue'),
            name: 'Model Library',
            meta: {
              key: "morph",
              service: "gptMorph"
            }
          },
          {
            path: 'gpt-concept/concept',
            component: () => import('@/views/services/gpt-concept/Concept.vue'),
            name: 'AI Concepter',
            meta: {
              key: "concepter",
              service: "gptConcepter"
            }
          },
          {
            path: 'gpt-concept/concept/new',
            component: () => import('@/views/services/gpt-concept/New.vue'),
            name: 'AI Concepter New',
            meta: {
              key: "concepter",
              service: "gptConcepter"
            }
          },
          {
            path: 'gpt-concept/concept/:id(\\d+)',
            component: () => import('@/views/services/gpt-concept/Detail.vue'),
            name: 'AI Concepter Detail',
            meta: {
              key: "concepter",
              service: "gptConcepter"
            }
          },
          {
            path: 'gpt-concept/sketches',
            component: () => import('@/views/services/gpt-concept/Sketches.vue'),
            name: 'Concept Sketches',
            meta: {
              key: "concepter-sketches",
              service: "gptConcepter"
            }
          },
          {
            path: 'gpt-drawing/drawing',
            component: () => import('@/views/services/gpt-drawing/Drawing.vue'),
            name: 'AI Drawing',
            meta: {
              key: "drawing",
              service: "gptDrawing"
            }
          },
          {
            path: 'gpt-drawing/drawing/new',
            component: () => import('@/views/services/gpt-drawing/New.vue'),
            name: 'AI Drawing New',
            meta: {
              key: "drawing",
              service: "gptDrawing"
            }
          },
          {
            path: 'gpt-drawing/drawing/:id(\\d+)',
            component: () => import('@/views/services/gpt-drawing/Detail.vue'),
            name: 'AI Drawing Detail',
            meta: {
              key: "drawing",
              service: "gptDrawing"
            }
          },
          {
            path: 'gpt-drawing/book',
            component: () => import('@/views/services/gpt-drawing/Book.vue'),
            name: 'Drawing Book',
            meta: {
              key: "drawing-book",
              service: "gptDrawing"
            }
          }
        ]
      },
      {
        path: 'my/profile',
        component: () => import('@/views/my/Profile.vue'),
        name: 'Profile',
        meta: {
          title: 'Profile',
        }
      },
      {
        path: 'my/order',
        component: () => import('@/views/my/Order.vue'),
        name: 'Order',
        meta: {
          title: 'Order',
        }
      },
      {
        path: 'admin',
        component: () => import('@/views/Admin.vue'),
        name: 'Admin',
        meta: {
          title: 'Admin',
          roles: ['admin']
        },
      }
    ]
  },
  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404', hidden: true }
]

export const constantRoutes = [
  {
    path: '/',
    component: Layout,
    name: 'Home',
    redirect: { name: 'Weekly Board' },
    meta: {
      title: 'Home',
    },
    children: [
      {
        path: 'services',
        component: ServiceLayout,
        redirect: { name: 'Weekly Board' },
        children: [
          {
            path: 'data-center/weekly-board',
            component: () => import('@/views/services/data/WeeklyBoard.vue'),
            name: 'Weekly Board',
            meta: {
              key: "weekly-board",
              service: "data",
              allowFree: true
            }
          },
          {
            path: 'visual/street-fashion',
            component: () => import('@/views/services/visual/Visual.vue'),
            name: 'Street Fashion',
            meta: {
              key: "street-fashion",
              service: "visual",
              allowFree: true,
              type: "street"
            }
          },
          {
            path: 'visual/window-fashion',
            component: () => import('@/views/services/visual/Visual.vue'),
            name: 'Window Fashion',
            meta: {
              key: "window-fashion",
              service: "visual",
              allowFree: true,
              type: "window"
            }
          },
        ]
      },
      {
        path: 'about',
        component: () => import('@/views/About.vue'),
        name: 'About',
        meta: {
          title: 'About',
        }
      }
    ]
  },
  {
    path: '/signin',
    name: 'signin',
    component: () => import('../views/Signin.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('../views/Signup.vue')
  },
  {
    path: '/verify',
    name: 'verify',
    component: () => import('../views/Verify.vue')
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/404'),
    hidden: true
  }
]

export const mergeRoutes = (constantRoutes, routes) => {
  let rst = [];
  for (let i = 0; i < constantRoutes.length; i++) {
    if (constantRoutes[i].path !== "/") {
      rst.push(constantRoutes[i]);
      continue
    }
    routes.forEach(r => {
      if (r.path === constantRoutes[i].path) {
        // TODO: hard code
        r.children[0].children = r.children[0].children.concat(constantRoutes[i].children[0].children);
        r.children.push(constantRoutes[i].children[1]);
      }
      rst.push(r);
    })
  }
  return rst;
}

export const createRouter = (routes) => new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
});

export default createRouter(constantRoutes)
