<template>
  <div class="footer">
    <span>{{ "v" + version }}</span>
    <el-divider direction="vertical"></el-divider>
    <span>Copyright © 2019 - 2024 F&Plus all rights reserved.</span>
    <el-divider direction="vertical"></el-divider>
    <a href="http://beian.miit.gov.cn" target="_blank">{{ siteB.icp }}</a>
    <el-divider direction="vertical"></el-divider>
    Go to <a :href="siteB.url" target="_blank">{{ siteB.title }}</a>
  </div>
</template>

<script>
export default {
  name: "AiFooter",
  data() {
    return {
      env: process.env.VUE_APP_ENV == "production.kr" ? "production" : process.env.VUE_APP_ENV,
      version: process.env.VUE_APP_VERSION,
    };
  },
  components: {},
  computed: {
    siteB() {
      if (process.env.VUE_APP_ENV && process.env.VUE_APP_ENV.endsWith(".kr")) {
        return { url: "https://console.fplusai.com.cn", title: "中国站", icp: "沪ICP备19010284号-1" }
      }
      return { url: "https://console.fplusai.com", title: "International", icp: "沪ICP备19010284号-2" }
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.footer {
  color: #666666;
  text-align: center;
  height: 50px;
  line-height: 50px;
  width: 100%;
  background-color: white;
  font-size: 12px;
  overflow: hidden;

  .footer-copyright {
    margin-right: 20px;
  }
}
</style>