<template>
  <section>
    <router-view class="app-main" :key="$route.path" />
  </section>
</template>

<script>
export default {
  name: "AppMain"
};
</script>

<style lang="scss" scoped>
.app-main {
  min-height: calc(100vh - 50px - 50px);
  position: relative;
  overflow: hidden;
}

.fixed-header + .app-main {
  padding-top: 50px;
}
</style>

