export default {
  common: {
    switchLang: 'Switch language successful',
    submit: 'Submit',
    exampleFile: 'Example file',
    processingData: 'Processing data, please wait...',
    preparingData: 'Preparing data, please wait...',
    indirecting: 'Indirecting, please wait...',
    pleaseConfirm: 'Please confirm',
    applySucc: 'Apply successful',
    saveSucc: 'Save successful',
    deleteSucc: 'Delete successful',
    pleaseSelect: 'Please select',
    new: 'New',
    image: 'Image',
    name: 'Name',
    detail: 'Detail',
    type: 'Type',
    search: 'Search',
    createAt: 'Create At',
    download: 'Download',
    delete: 'Delete',
    loading: 'Loading',
    downloading: 'Downloading',
    noMoreData: 'No more data',
    abort: 'Abort',
    ok: 'OK',
    cancel: 'Cancel',
    downloadAll: 'Download All',
    generate: 'Generate',
    regenerate: 'Regenerate',
    prompt: 'Prompt',
    action: 'Action',
    email: 'Email',
    username: 'Username',
    password: 'Password',
    import: 'Import',
    export: 'Export',
    brand: 'Brand',
    guide: 'Guide',
    report: 'Report',
    summaryReport: 'Summary Report',
    reportManual: 'Report Manual',
    keyword: 'Keyword',
    signin: 'Sign in',
    signout: 'Sign out',
    item: "Item",
    donotshow: 'Do not show',
    product: 'Product',
    selectAll: 'All',
    selectReverse: 'Reverse',
    week: 'Week',
    month: 'Month',
    all: 'All',
    distinguish: 'Distinguish',
    notDistinguish: 'Not Distinguish',
    noPermit: 'You have no permission!',
    proportion: 'Proportion',
    color: 'Color',
  },
  route: {
    home: 'Home',
    service: 'Services',
    document: 'Documents',
    about: 'About',
    manual: 'Manual',
    tagger: 'AI Image Tagger',
    analysis: 'Trend Analysis',
    imageDownload: 'Image Download',
    aeye: 'A.eye',
    my: 'My',
    profile: 'Profile',
    order: 'Order',
    new: 'New',
    detail: 'Detail',
    generate: 'Generate report',
    dataCenter: 'Data Center',
    weeklyBoard: 'Weekly Board',
    salesData: 'Sales Data',
    stockData: 'Stock Data',
    imageData: 'Image Data',
    visual: 'Visual',
    streetFashion: 'Street Fashion',
    streetTrend: 'Street Trend',
    windowFashion: 'Window Fashion',
    windowTrend: 'Window Trend',
    salesItem: 'Sales Item',
    salesTrend: 'Sales Trend',
    weeklySales: 'Weekly Sales',
    styleSearch: 'Style Search',
    newProduct: 'New Product',
    risingProduct: 'Rising Product',
    productDetail: 'Product Detail',
    channel: 'Channel',
    place: 'Place',
    concept: 'Concept',
    specificItem: 'Specific Item',
    trendMap: 'Trend Map',
    trendMatrix: 'Trend Matrix',
    imageTrendMap: 'Image Trend Map',
    reportConsulting: 'Report & Consulting',
    consultingReport: 'Consulting Report',
    globalTrendReport: 'Global Trend Report',
    globalTrend: 'Global Trend',
    nlpAnalysis: 'NLP Analysis',
    aiStyleScene: 'AI "StyleScene"',
    creator: 'Creator',
    myLookbook: 'My "StyleScene"',
    searchWords: 'Search Word',
    productName: 'Product Name',
    popularElements: 'Popular Elements',
    priceReport: 'Price Report',
    itemReport: 'Item Report',
    colorReport: 'Color Report',
    textileReport: 'Textile Report',
    best20Images: 'Best20 Image',
    best20Report: 'Best20 Report',
    fashionAIDesigner: 'Fashion AI "Designer"',
    aiDesign: "AI Design",
    fashionSketches: "Fashion Sketches",
    modelAIMorph: 'Model AI "Morph"',
    modelReplace: 'Model Replace',
    modelLibrary: 'Model Library',
    fashionAIConcepter: 'Fashion AI "Concept"',
    aiConcepter: 'AI "Concepter"',
    conceptSketches: 'Concepter Sketches',
    fashionAIDrawing: 'Fashion AI "Drawing"',
    aiDrawing: 'AI "Drawing"',
    drawingBook: 'Drawing Book',
    productReviews: 'Product Reviews',
    sns: 'SNS',
    itemDashboard: 'Item Dashboard',
    trendDashboard: 'Trend Dashboard',
    itemCompare: 'Item Compare',
    trendCompare: 'Trend Compare',
    itemSearch: 'Item Search',
    trendSearch: 'Trend Search',
    itemPrediction: 'Item Prediction',
    trendPrediction: 'Trend Prediction',
  },
  verify: {
    applyList: 'Apply list',
    approve: 'Approve',
    reject: 'Reject'
  },
  profile: {
    changePassword: 'Change password',
    originalPassword: 'Original password',
    newPassword: 'New password',
    confirmPassword: 'Confirm password'
  },
  order: {
    myServices: 'My Services',
    applyFreeTrail: 'Apply free trial',
    service: 'Service',
    level: 'Level',
    status: 'Status',
    expireAt: 'Expire At',
    remainImageCount: 'Remain Image Count',
    selectService: 'Select service for apply',
    noValidService: 'You have no valid service, click OK to check service list!',
    applied: 'Applied',
    approved: 'Approved',
    rejected: 'Rejected',
    basic: 'Basic',
    advanced: 'Advanced',
    premium: 'Premium'
  },
  signup: {
    title: 'Create your F&Plus account',
    confirmPassword: 'Confirm Password',
    phoneNo: 'PhoneNo',
    company: 'Company',
    area: 'Area',
    signup: 'Sign up',
    loginInfo: 'If your input is not factual, we will reject your application. Approval results will be notified by email.',
    emailRequired: 'Please enter email address',
    emailFormat: 'Please enter right email address format',
    userNameRequired: 'Please enter username',
    passwordRequired: 'Please enter password',
    confirmPasswordRequired: 'Please enter password again',
    phoneNoRequired: 'Please enter phone number',
    companyRequired: 'Please enter company',
    areaRequired: 'Please select a area',
    userNameLength: 'Username length between 4 and 20 characters',
    passwordLength: 'Password length between 6 and 32 characters',
    passwordNotMatch: 'The password is not the same',
    succSignup: 'Successfully signup',
    succSignupInfo: 'please signin with your new account',
    emailAlreadyExist: 'Email already exist',
    usernameAlreadyExist: 'Username already exist'
  },
  signin: {
    title: 'Sign in to F&Plus',
    emailUsername: 'Email or Username',
    forgot: 'Forgot password?',
    signupGuide: 'New to F&Plus?',
    signupLink: 'Create an account.',
    emailRequired: 'Please enter the correct email or username',
    passwordRequired: 'The password can not be less than 4 digits',
    redirectTitle: 'Account not available here',
    redirectMsg: 'To signin with this account, go to {code} site',
    intl: 'International',
    cn: 'China',
    go: 'Go'
  },
  about: {
    desc: 'Company description',
    manual: 'AI Tagger user manual',
    contact: 'Contact Us',
    descContent: 'F&PLUS is a company with AI (artificial intelligence) core technology, which can decompose pictures and detect videos through ML. Picture classification Demo Service refers to the core source technology that classifies fashion products according to (1. category 2. product type 3. Specific products) level, and at the same time, its extensive product planning, production, sales, sales data collection, etc.',
    contactTitle: 'Ms. Zhong (Market Development Manager)',
    contactTel: 'Tel: 15800974241(same with WeChat)',
  },
  tagger: {
    desc: 'Description',
    status: 'Status',
    execAt: 'Exec At',
    finishAt: 'Finish At',
    imageCount: 'Image Count',
    restart: 'Restart',
    deleteTask: 'Delete task?',
    restartSucc: 'Restart successful',
    defaultCategory: 'Default Category',
    imageSource: 'Image Source',
    imageFile: 'Image files',
    urlsInExcel: 'URLs in Excel',
    picsInExcel: 'Pics in Excel',
    uploadImages: 'Upload Images',
    uploadExcel: 'Upload Excel',
    clickToUpload: 'Click to Upload',
    succUpload: 'Successfully upload {length} data',
    submitTask: 'Submit task?',
    invalidImages: 'Invalid images',
    category: 'Category',
    itemType: 'Item Type',
    specificItem: 'Specific Item',
    runningTagger: 'Running AI Image Tagger, please wait...',
    finishImages: 'Finish images: {finishCount} / {totalCount}',
    waiting: 'Waiting',
    running: 'Running',
    done: 'Done',
    abort: 'Abort',
    error: 'Error'
  },
  analysis: {
    generateReport: 'Generate report',
    period: 'Period',
    date: 'Date',
    compareDate: 'Compare date',
    platform: 'Platform',
    deleteReport: 'Delete Report?',
    downloadAll: 'Download All',
    downloadImages: 'Downloading images: {finishCount} / {totalCount}',
    downloadProcess: 'Downloading : {finishCount} / {totalCount}',
    finishDownload: 'Finish download!',
    generateSucc: '{type} report generate successful!',
    reportExist: 'Report already exist with same date!',
    reportRegenerate: 'Report already exist, do you want to regenerate and cover the previous one?',
    group: "Group",
    criterion: "Criterion",
    sales: "Sales",
    salesVolume: "Sales Volume",
    styles: "Styles",
    graph: "Graph",
    fixedBar: "Cumulative vertical bar",
    percentageBar: "100% cumulative bar"
  },
  data: {
    sumStyle: "Sum Style",
    sumStock: "Sum Stock",
    colorAvg: "Avg Colors",
    sizeAvg: "Avg Sizes",
    sumMonthSale: "Sum Month Sale",
    saleStockPercentage: "Sale Stock Percentage",
    newProducts: "New Products",
    repeatProducts: "Repeat Products",
    currentYearProducts: "Current Year Products",
    previousYearProducts: "Previous Year Products",
  },
  visual: {
    when: "When",
    place: "Place",
    sex: "Sex",
    season: "Season"
  },
  salesItem: {
    item: "Item",
    styleAmount: "Style Amount",
    saleQty: "Sale Qty",
    minListPrice: "Min List Price",
    maxListPrice: "Max List Price",
    middleListPrice: "Middle List Price",
    minSalePrice: "Min Sale Price",
    maxSalePrice: "Max Sale Price",
    middleSalePrice: "Middle Sale Price",
    discountRate: "Discount Rate"
  },
  risingProduct: {
    item: "Item",
    weekSaleQty: "This Week Qty",
    lastWeekSaleQty: "Last Week Qty",
    increaseSaleQty: "Increase Qty",
    onSaleTime: "On Sale Time",
    listPrice: "Tag Price",
    salePrice: "Real Price"
  },
  newProduct: {
    item: "Item",
    weekSaleQty: "This Week Qty",
    season: "Season",
    colorCount: "Color",
    sizeCount: "Size",
    listPrice: "Tag Price",
    salePrice: "Real Price"
  },
  productDetail: {
    detail: "Product Detail",
    item: "Item",
    value: "Value",
    newValue: "Value_T",
    originalValue: "Value_S",
    saleQty: "Sale Qty",
    listPrice: "Tag Price",
    salePrice: "Real Price",
    detailSearch: "Detail Search"
  },
  analysisDetail: {
    pattern: "Pattern",
    fG: "FG",
    collar: "Collar",
    waistType: "Waist Style",
    pantLength: "Pants Length",
    pantsFit: "Pants Fit",
    channel: "Channel",
    place: "Place",
    concept: "Concept"
  },
  productInfo: {
    style: "Style",
    baseDate: "Base Date",
    logo: "Logo",
    yearSeason: "Year/Season",
    color: "Color",
    size: "Size",
    textile: "Textile",
    patternFG: "Pattern/FG",
    collar: "Collar",
    waistStyle: "Waist Style",
    pantsLength: "Pants Length",
    pantsFit: "Pants Fit",
    channel: "Channel",
    place: "Place",
    concept: "Concept",
    trendPoint: "Trend Point",
    overallSalesRanking: "Overall Sales Ranking",
    inItemSalesRanking: "In Item Sales Ranking",
    salesPositionAll: "Sales Position%_All",
    salesPositionItem: "Sales Position%_Item",
    sales: "Sales",
    salesVolume: "Sales Volume",
    realPrice: "Real Price"
  },
  nlpAnalysis: {
    basic: "Basic",
    compare: "Compare",
    trend: "Trend",
    businessType: "Business Type",
    date: "Date",
    compareDate: "Compare Date",
    startDate: "Start Date",
    endDate: "End Date",
    count: "Count",
    item: "Item",
    length: "Length",
    category: "Category",
  },
  imageTrendMap: {
    specificItem: 'Specific Item',
    volume: 'Volume',
    trend: 'Trend▲▼',
    quadrant: 'Quadrant',
    advice: 'Advice',
    overall: 'Overall',
    detail: 'Detail',
  },
  report: {
    target: 'Target',
    group: 'Group',
    product: 'Product',
    fashion: 'Fashion',
    etc: 'ETC',
    season: 'Season',
    currentYear: 'Current Year',
    previousYear: 'Previous Year',
    category: 'Category',
    item: 'Item',
    midPrice: 'MID Price',
    realMidPrice: 'REAL MID Price',
    tagMidPrice: 'TAG MID Price',
    discount: 'Discount',
    priceRange: 'Price Range',
    max: 'MAX',
    midt: 'MID(T)',
    midr: 'MID(R)',
    min: 'MIN',
    sales: "Sales",
    style: "Style",
    color: "color",
    sku: "SKU",
    stock: "STOCK",
    criterion: "Criterion",
    textile: "Textile",
  },
  globalTrend: {
    year: "Year",
    season: "Season",
    sex: "Sex",
    category: 'Category',
    item: 'Item',
    difference: 'Difference',
    proportionInRecency: 'Proportion in Recency (%)',
    specificItem: 'Specific Item',
    trendPoint: 'Trend Point',
    noticeWarning: 'Note : Photos provided from fashion shows are for internal reference purposes only. Downloading, re-sharing, or distributing them is strictly prohibited. We kindly request that the inspiration and information obtained through our service be used solely for your creative work and analysis.'
  },
  gpt: {
    noticeInfo: "This is a virtual image generated using the GPT model. The image is for reference purposes only and cannot be used for sharing or any other purposes. It is intended solely as a reference for product planning and design purposes.",
    noticeWarning: "Note: Efforts have been made to prevent the AI model from generating explicit or offensive content. However, if such content does appear, please report it using the designated button (Report button).",
    noticeWarningConcept: `Note: 1.Efforts have been made to prevent the AI model from generating explicit or offensive content. However, if such content does appear, please report it using the designated button (Report button).
2.Guidelines for Using Reference Brands: Please use reference brands solely as points of inspiration. This approach is intended to support the creation and design of new products based on your creativity. Instead of directly imitating reference brands, we encourage you to combine your unique ideas with these references to develop innovative designs.`,
    modelNotice: "[Notice]\n*Basic Reference Brand: {0}\n*Development of a custom reference brand for a client proceeds after discussion and agreement on a quote.",
    report: "Report",
    comfirmReport: "Confirm report this image?",
    sex: 'Sex',
    women: 'W',
    man: 'M',
    child: 'C',
    childGirl: 'CW',
    childBoy: 'CM',
    style: 'Style',
    place: 'Place',
    trendPoint: 'Trend Point',
    notice: 'Notice',
    desc: 'Description',
    status: 'Style',
    execAt: 'Exec At',
    finishAt: 'Finish At',
    imageCount: 'Image Count',
    restart: 'Restart',
    when: 'When',
    runningInfo: 'Running GPT Creator, please wait...',
    item: 'Item',
    specificItem: 'Specific Item',
    fit: 'Fit',
    collar: 'Collar',
    sleeve: 'Sleeve',
    front: 'Front',
    subsidiaryMaterials: 'Subsidiary Materials',
    features: 'Features',
    fabric: 'Fabric',
    pattern: 'Pattern',
    length: 'Length',
    pose: 'Pose',
    age: 'Age',
    face: 'Face',
    season: 'Season',
    targetFace: 'Target Face',
    sourceImage: 'Source Image',
    userAI: "User's AI",
    prompt: 'Prompt',
    mask: 'Mask',
    upload: 'Upload',
    selfDrawing: 'Self "Drawing"',
    select: 'Select',
    option: 'Option'
  }
}
