<template>
  <div class="notification">
    <el-dialog
      :title="title"
      :visible="dialogDisplay"
      @close="closeDialog"
      :close-on-click-modal="false"
      top="8vh"
      modal
    >
      <el-image
        v-if="imageUrl"
        :src="imageUrl | imageUrl(false)"
        fit="contain"
      ></el-image>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="closeDialog">{{
          $t("common.donotshow")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import dayjs from "dayjs";
import { fetchCurrent } from "@/api/notification";

export default {
  data() {
    return {
      dialogDisplay: false,
      currentId: 0,
      title: "",
      imageUrl: "",
    };
  },
  created() {
    this.fetchNotification();
  },
  methods: {
    fetchNotification() {
      fetchCurrent().then((response) => {
        if (response.success) {
          let newPassedIds = [];
          let passedIds = this.passedIds();
          response.result &&
            response.result.forEach((t) => {
              if (passedIds.includes(t.id)) {
                newPassedIds.push(t.id);
              }
            });
          Cookies.set("notify-pass", newPassedIds);
          response.result &&
            this.displayNotifications(response.result, newPassedIds);
        }
      });
    },
    displayNotifications(ns, passedIds) {
      for (let i = 0; i < ns.length; i++) {
        if (
          !passedIds.includes(ns[i].id) &&
          dayjs().isBefore(dayjs(ns[i].endAt))
        ) {
          this.currentId = ns[i].id;
          this.title = ns[i].title;
          this.imageUrl = ns[i].imageUrl;
          this.dialogDisplay = true;
          break;
        }
      }
    },
    closeDialog() {
      let passedIds = this.passedIds();
      passedIds.push(this.currentId);
      Cookies.set("notify-pass", passedIds);
      this.dialogDisplay = false;
      this.fetchNotification();
    },
    passedIds() {
      return JSON.parse(Cookies.get("notify-pass") || "[]");
    },
  },
};
</script>

<style lang="scss" scoped>
.notification {
  /deep/ .el-dialog__body {
    padding: 0;
  }
  /deep/ .el-dialog__footer {
    padding-bottom: 14px;
  }
}
</style>