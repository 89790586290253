import { Message } from 'element-ui'
import store from '@/store'

/**
 * 下载文件
 * @param {String} path - 下载地址/下载请求地址。
 * @param {String} name - 下载文件的名字/重命名（考虑到兼容性问题，最好加上后缀名）
 */
export function downloadFile(path, name, cb) {
  downloadFileAuth(path, name, null, cb)
}

/**
 * 下载文件
 * @param {String} path - 下载地址/下载请求地址。
 * @param {String} name - 下载文件的名字/重命名（考虑到兼容性问题，最好加上后缀名）
 * @param {String} token - Token
 */
export function downloadFileAuth(path, name, token, cb) {
  if (!path) {
    if (cb) {
      cb(false);
    }
    return;
  }
  if (!name) {
    let index = path.lastIndexOf("/");
    name = path.substring(index + 1, path.length);
  }
  const xhr = new XMLHttpRequest();
  if (path.lastIndexOf("?") == -1) {
    path += '?s=' + Math.random().toString()
  } else {
    path += '&s=' + Math.random().toString()
  }
  xhr.open('get', path);
  if (token) {
    xhr.setRequestHeader("Authorization", "Bearer " + token)
  }
  xhr.setRequestHeader("Accept-Language", store.getters.language)
  xhr.responseType = 'blob';
  xhr.send();
  xhr.onerror = function () {
    showMsg(xhr);
    if (cb) {
      cb(this.status === 200 || this.status === 304);
    }
  }
  xhr.onload = function () {
    if (this.status === 200 || this.status === 304) {
      // 如果是IE10及以上，不支持download属性，采用msSaveOrOpenBlob方法，但是IE10以下也不支持msSaveOrOpenBlob
      if ('msSaveOrOpenBlob' in navigator) {
        navigator.msSaveOrOpenBlob(this.response, name);
        return;
      }
      // const blob = new Blob([this.response], { type: xhr.getResponseHeader('Content-Type') });
      // const url = URL.createObjectURL(blob);
      const url = URL.createObjectURL(this.response);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } else {
      showMsg(xhr);
    }
    if (cb) {
      cb(this.status === 200 || this.status === 304);
    }
  };
}

function showMsg(xhr) {
  Message({
    message: "failed download file. state: " + xhr.statusText,
    type: 'error',
    duration: 5 * 1000
  })
}

export function serialize(obj, prefix) {
  var str = [],
    p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + "[" + p + "]" : p,
        v = obj[p];
      str.push((v !== null && typeof v === "object") ?
        serialize(v, k) :
        encodeURIComponent(k) + "=" + encodeURIComponent(v));
    }
  }
  return str.join("&");
}