import request from '@/utils/request'

export function signin(data) {
  return request({
    url: '/v1/users/signin',
    method: 'post',
    data
  })
}

export function signup(data) {
  return request({
    url: '/v1/users/signup',
    method: 'post',
    data
  })
}

export function signupVerify(data) {
  return request({
    url: '/v1/users/signup/verify',
    method: 'post',
    data
  })
}

export function signout() {
  return request({
    url: '/v1/users/signout',
    method: 'post',
  })
}

export function fetchMy() {
  return request({
    url: '/v1/users/my',
    method: 'get',
  })
}

export function fetchGuest() {
  return request({
    url: '/v1/users/guest',
    method: 'get',
  })
}

export function fetchUserServices() {
  return request({
    url: '/v1/user-services',
    method: 'get',
  })
}

export function createTrial(data) {
  return request({
    url: '/v1/user-services/trial',
    method: 'post',
    data
  })
}

export function changePassword(data) {
  return request({
    url: '/v1/users/change-password',
    method: 'post',
    data
  })
}