export const MenuList = {
    'tagger': {
        i18n: 'route.tagger',
        icon: "el-icon-brush",
    },
    'data-center': {
        i18n: 'route.dataCenter',
        icon: "el-icon-suitcase",
    },
    'weekly-board': {
        i18n: 'route.weeklyBoard',
        icon: "el-icon-data-board",
    },
    'sales': {
        i18n: 'route.salesData',
        icon: "el-icon-coin",
    },
    'stock': {
        i18n: 'route.stockData',
        icon: "el-icon-coin",
    },
    'image-data': {
        i18n: 'route.imageData',
        icon: "el-icon-picture-outline-round",
    },
    'global-trends': {
        i18n: 'route.globalTrend',
        icon: "el-icon-data-line",
    },
    'item-dashboard': {
        i18n: 'route.itemDashboard',
        icon: "el-icon-data-analysis",
    },
    'trend-dashboard': {
        i18n: 'route.trendDashboard',
        icon: "el-icon-data-analysis",
    },
    'item-compare': {
        i18n: 'route.itemCompare',
        icon: "el-icon-data-analysis",
    },
    'trend-compare': {
        i18n: 'route.trendCompare',
        icon: "el-icon-data-analysis",
    },
    'item-search': {
        i18n: 'route.itemSearch',
        icon: "el-icon-data-analysis",
    },
    'trend-search': {
        i18n: 'route.trendSearch',
        icon: "el-icon-data-analysis",
    },
    'item-prediction': {
        i18n: 'route.itemPrediction',
        icon: "el-icon-data-analysis",
    },
    'trend-prediction': {
        i18n: 'route.trendPrediction',
        icon: "el-icon-data-analysis",
    },
    'visual': {
        i18n: 'route.visual',
        icon: "el-icon-monitor",
    },
    'street-fashion': {
        i18n: 'route.streetFashion',
        icon: "el-icon-film",
    },
    'street-trend': {
        i18n: 'route.streetTrend',
        icon: "el-icon-notebook-2",
    },
    'window-fashion': {
        i18n: 'route.windowFashion',
        icon: "el-icon-film",
    },
    'window-trend': {
        i18n: 'route.windowTrend',
        icon: "el-icon-notebook-2",
    },
    'global-trend': {
        i18n: 'route.globalTrend',
        icon: "el-icon-film",
    },
    'analysis': {
        i18n: 'route.analysis',
        icon: "el-icon-data-line",
    },
    'sales-item': {
        i18n: 'route.salesItem',
        icon: "el-icon-pie-chart",
    },
    'sales-trend': {
        i18n: 'route.salesTrend',
        icon: "el-icon-reading",
    },
    'weekly-sales': {
        i18n: 'route.weeklySales',
        icon: "el-icon-reading",
    },
    'style-search': {
        i18n: 'route.styleSearch',
        icon: "el-icon-paperclip",
    },
    'new-product': {
        i18n: 'route.newProduct',
        icon: "el-icon-shopping-bag-2",
    },
    'rising-product': {
        i18n: 'route.risingProduct',
        icon: "el-icon-shopping-bag-2",
    },
    'specific-item': {
        i18n: 'route.specificItem',
        icon: "el-icon-price-tag",
    },
    'product-detail': {
        i18n: 'route.productDetail',
        icon: "el-icon-shopping-bag-1",
    },
    'channel': {
        i18n: 'route.channel',
        icon: "el-icon-shopping-bag-1",
    },
    'place': {
        i18n: 'route.place',
        icon: "el-icon-shopping-bag-1",
    },
    'concept': {
        i18n: 'route.concept',
        icon: "el-icon-shopping-bag-1",
    },
    'best20-report': {
        i18n: 'route.best20Report',
        icon: "el-icon-data-analysis",
    },
    'best20-images': {
        i18n: 'route.best20Images',
        icon: "el-icon-data-analysis",
    },
    'tps': {
        i18n: 'route.trendMap',
        icon: "el-icon-data-line",
    },
    'matrix': {
        i18n: 'route.trendMatrix',
        icon: "el-icon-data-line",
    },
    'report': {
        i18n: 'route.reportConsulting',
        icon: "el-icon-notebook-1",
    },
    'image-trend-map': {
        i18n: 'route.imageTrendMap',
        icon: "el-icon-wallet",
    },
    'price-report': {
        i18n: 'route.priceReport',
        icon: "el-icon-reading",
    },
    'item-report': {
        i18n: 'route.itemReport',
        icon: "el-icon-reading",
    },
    'color-report': {
        i18n: 'route.colorReport',
        icon: "el-icon-reading",
    },
    'textile-report': {
        i18n: 'route.textileReport',
        icon: "el-icon-reading",
    },
    'consulting-report': {
        i18n: 'route.consultingReport',
        icon: "el-icon-reading",
    },
    'global-trend-report': {
        i18n: 'route.globalTrendReport',
        icon: "el-icon-notebook-2",
    },
    'nlp': {
        i18n: 'route.nlpAnalysis',
        icon: "el-icon-cpu",
    },
    'popular-elements': {
        i18n: 'route.popularElements',
        icon: "el-icon-attract",
    },
    'product-name': {
        i18n: 'route.productName',
        icon: "el-icon-takeaway-box",
    },
    'search-word': {
        i18n: 'route.searchWords',
        icon: "el-icon-chat-dot-square",
    },
    'product-reviews': {
        i18n: 'route.productReviews',
        icon: "el-icon-reading",
    },
    'sns': {
        i18n: 'route.sns',
        icon: "el-icon-notebook-2",
    },
    'gpt': {
        i18n: 'route.aiStyleScene',
        icon: "el-icon-connection",
    },
    'creator': {
        i18n: 'route.creator',
        icon: "el-icon-magic-stick",
    },
    'lookbook': {
        i18n: 'route.myLookbook',
        icon: "el-icon-notebook-2",
    },
    'gpt-designer': {
        i18n: 'route.fashionAIDesigner',
        icon: "el-icon-connection",
    },
    'designer': {
        i18n: 'route.aiDesign',
        icon: "el-icon-magic-stick",
    },
    'sketches': {
        i18n: 'route.fashionSketches',
        icon: "el-icon-notebook-2",
    },
    'gpt-morph': {
        i18n: 'route.modelAIMorph',
        icon: "el-icon-c-scale-to-original",
    },
    'morph': {
        i18n: 'route.modelReplace',
        icon: "el-icon-magic-stick",
    },
    'library': {
        i18n: 'route.modelLibrary',
        icon: "el-icon-notebook-2",
    },
    'gpt-concepter': {
        i18n: 'route.fashionAIConcepter',
        icon: "el-icon-c-scale-to-original",
    },
    'concepter': {
        i18n: 'route.aiConcepter',
        icon: "el-icon-magic-stick",
    },
    'concepter-sketches': {
        i18n: 'route.conceptSketches',
        icon: "el-icon-notebook-2",
    },
    'gpt-drawing': {
        i18n: 'route.fashionAIDrawing',
        icon: "el-icon-c-scale-to-original",
    },
    'drawing': {
        i18n: 'route.aiDrawing',
        icon: "el-icon-magic-stick",
    },
    'drawing-book': {
        i18n: 'route.drawingBook',
        icon: "el-icon-notebook-2",
    },
    'aeye': {
        i18n: 'route.aeye',
        icon: "el-icon-view",
    }
}