<template>
  <div class="navbar">
    <a :href="homeURL">
      <img class="logo-container" alt="logo" src="/logo.png" height="48" />
    </a>
    <el-menu :default-active="activeIndex" class="menu-container" mode="horizontal" @select="handleSelect"
      ref="navmenu">
      <el-menu-item index="/">{{ $t("route.home") }}</el-menu-item>
      <el-menu-item index="/services">{{ $t("route.service") }}</el-menu-item>
      <!-- <el-menu-item index="/documents" disabled>{{
        $t("route.document")
      }}</el-menu-item> -->
      <!-- <el-menu-item index="/about">{{ $t('route.about') }}</el-menu-item> -->
      <el-menu-item index="/manual">{{ $t("route.manual") }}</el-menu-item>
      <el-menu-item index="/contact">{{ $t("about.contact") }}</el-menu-item>
    </el-menu>
    <div class="right-menu">
      <el-dropdown class="right-menu-item hover-effect" trigger="click" @command="handleSetLanguage">
        <el-button class="i18n" type="text">
          <svg-icon class-name="i18n-icon" icon-class="international" />
          <span class="i18n-text">{{ language }}</span>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :disabled="language === 'English'" command="en">English</el-dropdown-item>
          <el-dropdown-item :disabled="language === '中文'" command="zh">中文</el-dropdown-item>
          <el-dropdown-item :disabled="language === '한국어'" command="ko">한국어</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <router-link v-if="isAdmin" to="/admin">
        <div class="right-menu-item hover-effect" :class="{ active: activeIndex == '/admin' }">
          <i class="el-icon-s-cooperation"></i>
        </div>
      </router-link>
      <el-dropdown class="right-menu-item hover-effect" :class="{ active: activeIndex == '/my' }" trigger="click"
        v-if="hasSignin">
        <i class="el-icon-user-solid"></i>
        <el-dropdown-menu slot="dropdown">
          <router-link to="/my/profile">
            <el-dropdown-item>{{ $t("route.profile") }}</el-dropdown-item>
          </router-link>
          <router-link to="/my/order">
            <el-dropdown-item>{{ $t("route.order") }}</el-dropdown-item>
          </router-link>
          <el-dropdown-item divided @click.native="handleSignout">
            <span style="display: block">{{ $t("common.signout") }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <router-link v-else :to="'/signin?redirect=' + $route.fullPath">
        <div class="right-menu-item hover-effect">
          <i class="el-icon-user-solid"></i>
        </div>
      </router-link>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="40%" :title="$t('common.pleaseSelect')" @close="handleDialogClose">
      <el-checkbox-group class="manual-select" v-model="manualList">
        <el-checkbox label="zh">中文</el-checkbox>
        <el-checkbox label="ko">한국어</el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button class="excel-dl-btn" size="small" @click="handleDownload">{{
      $t("common.download")
    }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { HomeURL, ContactUsURL } from "@/constants";
import { setLanguage } from "@/lang/index";
import { downloadFile } from "@/utils/download-file";
import { ossRoot } from "@/utils/server";
import { signout } from "@/api/user";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      homeURL: HomeURL,
      contactUsURL: ContactUsURL,
      activeIndex: null,
      dialogVisible: false,
      manualList: ["ko", "zh"],
    };
  },
  watch: {
    $route(route) {
      // if you go to the redirect page, do not update the breadcrumbs
      if (route.path.startsWith("/redirect/")) {
        return;
      }
      this.getActiveNavMenu();
    },
  },
  created() {
    this.getActiveNavMenu();
  },
  computed: {
    hasSignin() {
      return this.$store.getters.my && this.$store.getters.my.id > 0;
    },
    isAdmin() {
      return (
        this.$store.getters.my && this.$store.getters.my.adminType && this.$store.getters.my.adminType.length > 0
      );
    },
    language() {
      switch (this.$store.getters.language) {
        case "en":
          return "English";
        case "zh":
          return "中文";
        case "ko":
          return "한국어";
      }
      return "";
    },
  },
  methods: {
    handleSelect(key) {
      if (key === "/") {
        window.location.assign(HomeURL);
      } else if (key === "/manual") {
        this.dialogVisible = true;
        this.activeIndex = "/manual";
      } else if (key === "/contact") {
        window.open(ContactUsURL, "_blank");
        this.handleDialogClose();
      } else if (key && key !== this.activeIndex) {
        this.$router.push(key);
      }
    },
    getActiveNavMenu() {
      if (this.$route.meta && this.$route.meta.service) {
        this.activeIndex = "/services";
      } else if (this.$route.name && this.$route.name === "About") {
        this.activeIndex = "/about";
      } else if (this.$route.name && this.$route.name === "Admin") {
        this.activeIndex = "/admin";
      } else if (
        this.$route.name &&
        (this.$route.name === "Profile" || this.$route.name === "Order")
      ) {
        this.activeIndex = "/my";
      } else {
        this.activeIndex = null;
      }
    },
    handleSetLanguage(lang) {
      setLanguage(lang, () => {
        setTimeout(this.$router.go, 200);
      });
    },
    handleDownload() {
      this.dialogVisible = false;
      let env = process.env.VUE_APP_ENV;
      if (!env) {
        env = "staging";
      }
      if (env === "production.kr") { env = "production" }
      this.manualList.forEach((e) => {
        if (e === "ko") {
          downloadFile(
            ossRoot + "/" + env + "/common/MANUAL_20240330_KR.pdf"
          );
        } else if (e === "zh") {
          downloadFile(
            ossRoot + "/" + env + "/common/MANUAL_20240330_CN.pdf"
          );
        }
      });
    },
    handleDialogClose() {
      // 关闭手册对话框时，强制修改ActiveIndex
      if (this.$route.meta && this.$route.meta.service) {
        this.$refs.navmenu.activeIndex = "/services";
      } else if (this.$route.name && this.$route.name === "About") {
        this.$refs.navmenu.activeIndex = "/about";
      } else {
        this.$refs.navmenu.activeIndex = null;
      }
      if (this.$route.name && this.$route.name === "Admin") {
        this.activeIndex = "/admin";
      } else if (
        this.$route.name &&
        (this.$route.name === "Profile" || this.$route.name === "Order")
      ) {
        this.activeIndex = "/my";
      }
    },
    handleSignout() {
      signout().then(() => {
        this.$store.dispatch("user/resetToken");
      });
      // remove notify records
      Cookies.set("notify-pass", []);
      this.$router
        .push(`/signin?redirect=${this.$route.fullPath}`)
        .catch(() => { });
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  position: relative;
  overflow: hidden;
  background: #fff;
  border-bottom: 1px solid #eaecef;
  border-collapse: collapse;

  .logo-container {
    margin-left: 20px;
    margin-right: 20px;
    float: left;
  }

  .menu-container {
    float: left;

    /deep/ .el-submenu.is-active .el-submenu__title {
      border-bottom: 3px solid #0099ff;
    }

    .el-menu-item.is-active {
      border-bottom: 3px solid #0099ff;
    }
  }

  .right-menu {
    float: right;
    height: 50px;
    line-height: 50px;

    .active {
      border-bottom: 3px solid #0099ff;
    }

    &:focus {
      outline: none;
    }

    .right-menu-item {
      margin-right: 20px;
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;
      // border: none;

      .i18n {
        color: #5a5e66;
        font-size: 15px;
        height: 49px;
        vertical-align: inherit;
      }

      .i18n-icon {
        margin-right: 10px;
      }

      .i18n-text {
        font-size: 14px;
      }

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }
  }

  .manual-select {
    margin-top: 10px;
  }
}
</style>
