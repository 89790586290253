const state = {
  needRecount: false
}

const mutations = {
  SET_NEED_RECOUNT: (state, needRecount) => {
    state.needRecount = needRecount
  },
}

const actions = {
  setNeedRecount({ commit }, needRecount) {
    commit('SET_NEED_RECOUNT', needRecount)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
