const getters = {
  language: state => state.app.language,
  permission_routes: state => state.permission.routes,
  token: state => state.user.token,
  my: state => state.user.my,
  allowService: state => state.user.allowService,
  permitMenu: state => state.user.permitMenu,
  needRecount: state => state.admin.needRecount,
  t2Flatten: state => state.category.t2Flatten, // t1, t2 扁平化，带t3
  t2AdditionalFlatten: state => state.category.t2AdditionalFlatten, // t1, t2 扁平化，带t3
  t1t2Simple: state => state.category.t1t2Simple, // t1, t2, 不带t3
  taggerMapping: state => state.category.taggerMapping,
  taggerOptions: state => state.category.taggerOptions,
  taggerTrendPoint: state => state.category.taggerTrendPoint,
  taggerTrendPointTagger: state => state.category.taggerTrendPointTagger,
}
export default getters
