<template>
  <section>
    <sidebar :class="{ lite: lite }" class="sidebar-container" />
    <router-view v-if="allow && permit" :class="{ lite: lite }" class="view-container" :key="$route.path" />
    <div v-else-if="!permit" class="view-container">
      <el-col :sm="{ span: 16, offset: 4 }" :lg="{ span: 12, offset: 6 }">
        <el-card class="service-prompt" shadow="never">
          <div slot="header" class="clearfix">
            <span>{{ $t("common.prompt") }}</span>
          </div>
          {{ $t("common.noPermit") }}
        </el-card>
      </el-col>
    </div>
    <div v-else-if="!allow" class="view-container">
      <el-col :sm="{ span: 16, offset: 4 }" :lg="{ span: 12, offset: 6 }">
        <el-card class="service-prompt" shadow="never">
          <div slot="header" class="clearfix">
            <span>{{ $t("common.prompt") }}</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="clickOK">{{ $t("common.ok") }}</el-button>
          </div>
          {{ $t("order.noValidService") }}
        </el-card>
      </el-col>
    </div>
  </section>
</template>

<script>
import { Sidebar } from "../components";
import { MenuList } from "@/router/menu";
export default {
  components: {
    Sidebar,
  },
  data() {
    return {
      lite: false,
      allow: this.$store.getters.allowService,
      permit: this.$store.getters.permitMenu,
    };
  },
  created() {
    let m = MenuList[this.$route.meta["key"]];
    if (!m || !m.i18n) {
      return;
    }
    document.title = 'F&Plus - ' + this.$t(m.i18n);
  },
  methods: {
    clickOK() {
      this.$router.push("/my/order").catch(() => { });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.view-container {
  margin-left: $sideBarWidth + 20px !important;
  margin-right: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.service-prompt {
  margin-top: 20vh;
}

.sidebar-container {
  transition: width 0.28s;
  width: $sideBarWidth;
  background-color: #eaecef;
  height: 100%;
  z-index: 1001;
  position: absolute;
  padding: 10px 0;

  /deep/ .el-menu {
    border: none;
  }
}
</style>