console.log('FPlus console APP environment:', process.env.VUE_APP_ENV, ', version:', process.env.VUE_APP_VERSION);

export const aiApiRoot = (() => {
    if (process.env.VUE_APP_ENV === "production") {
        return 'https://console.fplusai.com.cn/api';
    } else if (process.env.VUE_APP_ENV === "production.kr") {
        return 'https://console.fplusai.com/api';
    } else if (process.env.VUE_APP_ENV === "staging") {
        return 'https://console.staging.fplusai.com.cn/api';
    } else {
        return 'https://console.staging.fplusai.com.cn/api';
        // return 'https://staging.api.fplusai.com.cn';
        // return 'http://localhost:5000';
    }
})();

export const ossRoot = (() => {
    if (process.env.VUE_APP_ENV === "production") {
        return 'https://static.fplusai.com.cn';
    } else if (process.env.VUE_APP_ENV === "production.kr") {
        return 'https://ai-static-files-kr.oss-ap-northeast-2.aliyuncs.com';
    } else if (process.env.VUE_APP_ENV === "staging") {
        return 'https://ai-static-files.oss-cn-shanghai.aliyuncs.com';
    } else {
        return 'https://ai-static-files.oss-cn-shanghai.aliyuncs.com';
    }
})();
